import Api from "../../Api";

export const END_POINT_POSTFILE = 'user/file';
export const END_POINT_CREATEFILE = 'user/file/create';
// export const END_POINT_POSTFILEFORDROPZONE = 'http://127.0.0.1:8081/api/v1/user/file';
// export const AppBaseUrl = 'http://127.0.0.1:8081/'

export const END_POINT_POSTFILEFORDROPZONE = 'https://ferreprod.ferrebaztan.com/FERREPROD-APP-BACK/public/api/v1/user/file';
export const AppBaseUrl = 'https://ferreprod.ferrebaztan.com/FERREPROD-APP-BACK/public/'

export default{
  PostFile(data, auth){
    return Api.post(END_POINT_POSTFILE, data, {
      headers: {
        'Authorization': 'Bearer ' +  auth.access_token
      }
    });
  },
  CreateFileReg(data,auth){
    return Api.post(END_POINT_CREATEFILE, data, {
        headers: {
            'Authorization': 'Bearer ' + auth.access_token
        }
    });
  },
  END_POINT_POSTFILE,
  END_POINT_POSTFILEFORDROPZONE,
  AppBaseUrl
}