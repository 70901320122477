<template>
  <div>
    <h4>Diseños y archivos</h4>
    <div>
      <form 
        class="dropzone border"
        enctype="multipart/form-data"
        id="dropzone"
        >
      </form>
    </div>
    <div class="file-container">
      <table class="table w-100">
        <thead>
          <tr>
            <th scope="col">No.</th>
            <th scope="col">Nombre</th>
            <th scope="col" colspan="2">Acciones</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(file, index) in files" :key="file.id">
            <th scope="row">{{index+1}}</th>
            <td class="text-break">{{file.name}}</td>
            <td><a :href="`${AppBaseUrl}${file.path}`" target="_blanc" class="btn btn-info">Ver</a></td>
            <td><button class="btn btn-danger">Borrar</button></td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import Archivos from '../api/admin/archivos/Archivos';
import Dropzone from "dropzone";
import "dropzone/dist/dropzone.css";
import Swal from "sweetalert2"

export default {
  props: ['view', 'idItemView','files'],
  data(){
    return{
      AppBaseUrl:null
    }
  },
  mounted(){
    //let me = this;
    let auth = JSON.parse(localStorage.autentication);
    console.log("en filesystem")
    console.log(this.view)
    console.log(this.idItemView)
    const id_cotizacion = this.idItemView
    this.AppBaseUrl = Archivos.AppBaseUrl

    console.log(Archivos.AppBaseUrl);

    if (document.getElementById('dropzone')) {
      var myDropzone = new Dropzone(
        "#dropzone",
        {
          url: `${Archivos.END_POINT_POSTFILEFORDROPZONE}`,
          headers: {
            'Authorization': 'Bearer ' +  auth.access_token
          },
          dictDefaultMessage:" + Arrastre archivos aqui ...",
          uploadMultiple: true,
          addRemoveLinks: true,
          dictRemoveFile: "Borrar",
          createImageThumbnails: false,
          acceptedFiles: 'image/*,application/pdf',
          disablePreviews:true,
          dictInvalidFileType:'No puede subir este tipo de archivos'
        }
      );

      myDropzone.on("successmultiple", function(files, response) {
        alert("Éxito al guardar")
        console.log(response)
        console.log(files)
        // cuando se guarda, tenemos que guardar el nombre que en este caso sera el paht
        // que es el dato que nos retorna la respuesta:
        const data = {
          quote_id:id_cotizacion,
          response:response,
          files:files
        }

        let auth = JSON.parse(localStorage.autentication);
        console.log(JSON.stringify(data))

        Archivos.CreateFileReg(JSON.stringify(data),auth)
        .then((response) => {
          Swal.fire({
            title: "Genial!",
            text: response.data.message,
            icon: "success",
          });
          myDropzone.reset()
        })
        .catch((error) => {
          const obj = error.response.data.errors;
          Object.entries(obj).forEach( elements => 
            Swal.fire({
              title: "Que mal!",
              text: elements[1][0],
              icon: "error",
            })
          );
        });
      });

      myDropzone.on("removedfile", function(file) {
        console.log(file)
      });
    }
  }
}
</script>

<style scoped>
  .file-container{
    height:250px;
    overflow:scroll;
    padding:15px;
    margin-bottom:10px;
  }
</style>